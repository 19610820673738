<template>
  <v-menu :id="id" ref="menu" :content-props="{ 'data-belongs-to': id }">
    <template #activator="{ props }">
      <slot name="activator" v-bind="{ props: getProps(props) }" />
    </template>

    <div class="menu-content w-fit overflow-hidden rounded shadow-xl">
      <slot />
    </div>
  </v-menu>
</template>

<script lang="ts" setup>
import { uniqueId } from "lodash-es";
import { ref } from "vue";

import type { IUIMenuExpose } from "@/modules/ui-menu/ui-menu-types";

const id = uniqueId("ui-menu-");
const menu = ref();

function close() {
  menu.value?.activatorEl?.click();
}

function getProps<T extends object>(props: T) {
  return {
    ...props,
    "aria-owns": id,
  };
}

defineExpose<IUIMenuExpose>({ close });
</script>

<style scoped lang="scss">
:deep(.v-list) {
  padding-top: 0;
  padding-bottom: 0;
}

.menu-content {
  max-height: 100vh;
  // overflow-y: auto;
}
</style>
