<template>
  <div>
    <UIMenu>
      <template #activator="{ props: activatorProps }">
        <v-btn
          v-bind="activatorProps"
          icon
          size="x-large"
          data-test-id="user-avatar-toggle-button"
        >
          <UIAvatar data-test-id="user-avatar">{{ initials }}</UIAvatar>
        </v-btn>
      </template>
      <UICard elevation="none">
        <div class="flex flex-col items-center justify-center p-4 text-center">
          <div class="text-2xl">
            <UIAvatar data-test-id="user-avatar">{{ initials }}</UIAvatar>
          </div>
          <h3 class="mt-2 text-lg font-bold" data-test-id="user-name">
            {{ fullName }}
          </h3>
          <p class="mt-1 text-xs" data-test-id="user-email">
            {{ email }}
          </p>
          <v-divider class="my-3" />
          <AppLanguageSwitcher />
          <v-divider class="my-3" />
          <UIButton
            :disabled="loggedInAsAdmin"
            variant="clear"
            data-test-id="change-password-button"
            @click="changePassword()"
          >
            {{ $t("changePasswordButton.label") }}
          </UIButton>
          <v-divider class="my-3" />
          <UIButton
            variant="clear"
            data-test-id="logout-button"
            @click="logout()"
          >
            {{ $t("logoutButton.label") }}
          </UIButton>
        </div>
      </UICard>
    </UIMenu>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";

import { useIntercom } from "@/composables/use-intercom";
import { useUser } from "@/composables/use-user";
import UIAvatar from "@/modules/ui/avatars/components/UIAvatar.vue";
import UIButton from "@/modules/ui/buttons/components/UIButton.vue";
import UICard from "@/modules/ui-card/components/UICard.vue";
import UIMenu from "@/modules/ui-menu/components/UIMenu.vue";
import { useAuthStore } from "@/store/auth-store";

import AppLanguageSwitcher from "./AppLanguageSwitcher.vue";

const { fullName, initials, email } = useUser();
const { shutdownIntercom } = useIntercom();
const authStore = useAuthStore();

const loggedInAsAdmin = computed(() => authStore.loggedInAsAdmin === true);

async function logout() {
  shutdownIntercom();

  await authStore.logout();
}

function changePassword() {
  authStore.changePassword();
}
</script>
