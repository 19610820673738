<template>
  <AppConfirmDialog v-if="isOpen" :title="title">
    <div v-if="message || slots.default">
      {{ $t(message) }}
      <slot />
    </div>
    <div class="my-4 text-center">
      <v-progress-circular indeterminate :size="50" />
    </div>
    <template #footer>
      <UIButton @click="close(false)">{{ $t(cancelLabel) }}</UIButton>
    </template>
  </AppConfirmDialog>
</template>

<script lang="ts" setup>
import { ref, useSlots } from "vue";

import UIButton from "@/modules/ui/buttons/components/UIButton.vue";

import AppConfirmDialog from "./AppConfirmDialog.vue";

const slots = useSlots();

interface Props {
  okLabel?: string;
  cancelLabel?: string;
  title?: string;
  message?: string;
  loading?: boolean;
  color?: string;
  resolve?: (value: boolean) => void;
}

const props = withDefaults(defineProps<Props>(), {
  cancelLabel: "confirmDialog.cancel",
  okLabel: "confirmDialog.ok",
  color: "primary",
  title: undefined,
  loading: false,
  message: "",
  resolve: undefined,
});

const emit = defineEmits<{
  (e: "closed", data: boolean): void;
}>();

const isOpen = ref(true);

function close(value: boolean): void {
  emit("closed", value);
  props.resolve?.(value);
}
</script>

<style scoped lang="scss">
.v-progress-circular {
  opacity: 0.5;
}
</style>
