<template>
  <DefineTemplate v-slot="{ item, otherProps }">
    <v-list-item v-bind="{ ...otherProps, ...item.props }">
      <template v-if="isComponentIcon(item.icon)" #prepend>
        <UIIcon class="v-icon v-icon--size-default">
          <component :is="item.icon" class="app-main-menu-custom-icon" />
        </UIIcon>
      </template>
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </DefineTemplate>

  <v-navigation-drawer
    ref="drawerRef"
    v-model="drawer"
    class="app-main-menu"
    :width="drawerStore.width"
    mobile-breakpoint="md"
  >
    <!--
    Causes problems (on windows machines) with scrollbars.
    https://jam.dev/c/94620034-fce0-49b6-9d99-a55aea53942b

    <UIDraggableSize :drawer="drawerElement" />
    -->
    <template v-for="section in navigationSections" :key="section.id">
      <v-list-subheader v-if="!mini" class="ml-4 uppercase">
        {{ $t(section.title) }}
      </v-list-subheader>

      <v-list>
        <template v-for="item in section.items" :key="item.id">
          <v-list-group
            v-if="isMenuGroup(item)"
            :prepend-icon="item.icon"
            data-test-id="main-menu-group"
          >
            <!-- Menu group item -->
            <template #activator="{ props: activatorProps }">
              <ReuseTemplate :item="item" :other-props="activatorProps" />
            </template>
            <template v-for="subItem in item.items" :key="subItem.id">
              <ReuseTemplate :item="subItem" />
            </template>
          </v-list-group>
          <template v-else>
            <!-- Menu item -->
            <ReuseTemplate :item="item" />
          </template>
        </template>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { createReusableTemplate } from "@vueuse/core";
import { ref, shallowRef, watch } from "vue";
import { useRoute } from "vue-router";

import { useManagerFeature } from "@/composables/use-manager-feature";
import {
  isComponentIcon,
  isMenuGroup,
  useNavigationBuilder,
} from "@/composables/use-navigation-builder";
import { useUser } from "@/composables/use-user";
import UIIcon from "@/modules/ui/general/components/UIIcon.vue";
import { routeNames } from "@/router/route-names";
import { useNavigationDrawerStore } from "@/store/navigation-drawer-store";
import MaterialSymbolsPriorityOutline from "~icons/material-symbols/priority-outline";

const [DefineTemplate, ReuseTemplate] = createReusableTemplate();

const drawer = defineModel<boolean>("open", { default: true });

const { permissions } = useUser();
const route = useRoute();
const mini = ref(false);
const { getMenu, getGroup, getItem } = useNavigationBuilder();
const { hiddenFeatures } = useManagerFeature();
const { isJaneActivated } = useUser();

const drawerRef = ref();
/*
// TODO enable again when resizable navbar is needed
const drawerElement = computed(() => {
  return drawerRef.value?.$el.nextElementSibling;
});
 */
const drawerStore = useNavigationDrawerStore();

const navigationSections = shallowRef<ReturnType<typeof getMenu>>([]);
watch(
  [permissions, hiddenFeatures, () => route.name],
  () => {
    const adminSection = {
      title: "navigationDrawer.admin",
      items: [
        getItem(routeNames.connectedApps, "mdi-connection"),
        getItem(routeNames.userOverview, "mdi-account-group-outline"),
        getGroup({
          title: "default.menu.storefront",
          icon: "mdi-storefront-outline",
          items: [
            getItem(routeNames.globalSettings, "mdi-web"),
            getItem(
              routeNames.locationSettingsOverview,
              "mdi-map-marker-outline"
            ),
          ],
        }),
      ],
    };

    if (isJaneActivated.value) {
      adminSection.items.push(
        getGroup({
          title: "default.menu.janeGPT",
          icon: "mdi-robot-outline",
          items: [
            getItem(routeNames.chatLogsOverview, "mdi-chat-question-outline"),
          ],
        })
      );
    }

    navigationSections.value = getMenu([
      {
        title: "navigationDrawer.superAdmin",
        items: [
          getItem(
            routeNames.tenantsOverview,
            "mdi-account-box-multiple-outline"
          ),
          getItem(routeNames.maintenance, "mdi-wrench-cog"),
        ],
      },
      {
        title: "navigationDrawer.dashboard",
        items: [
          getItem(routeNames.tasksOverview, MaterialSymbolsPriorityOutline),
          getItem(routeNames.facilityMapOverview, "mdi-layers-outline"),
          getItem(
            routeNames.dashboardOverview,
            "mdi-chart-timeline-variant-shimmer"
          ),
        ],
      },
      {
        title: "navigationDrawer.salesManagement",
        items: [
          getItem(routeNames.bookingOverview, "mdi-calendar-refresh"),
          getItem(routeNames.customerOverview, "mdi-account-multiple-outline"),
          getItem(routeNames.invoiceOverview, "mdi-file-document-edit-outline"),
          getItem(routeNames.unitOverview, "mdi-lock-open-outline"),
        ],
      },
      {
        title: "navigationDrawer.locationManagement",
        items: [
          getItem(routeNames.locationOverview, "mdi-map-marker-outline"),
          getItem(routeNames.unitTypeOverview, "mdi-cube-outline"),
          getItem(routeNames.insuranceOverview, "mdi-shield-lock-outline"),
          getItem(
            routeNames.depositOverview,
            "mdi-credit-card-refresh-outline"
          ),
          getItem(routeNames.productOverview, "mdi-cart-outline"),
          getItem(routeNames.discountOverview, "mdi-tag-outline"),
          getGroup({
            items: [
              getItem(routeNames.emailOverview, "mdi mdi-email-fast-outline"),
              getItem(
                routeNames.emailTemplateOverview,
                "mdi-email-edit-outline"
              ),
              getItem(routeNames.emailDomainOverview, "mdi-at"),
            ],
            icon: "mdi-email-multiple-outline",
            title: "default.menu.emails",
          }),
        ],
      },
      adminSection,
      {
        title: "navigationDrawer.feedback",
        items: [getItem(routeNames.votingPortal, "mdi-thumb-up-outline")],
      },
    ]);
  },
  { immediate: true }
);
</script>

<style lang="scss">
.app-main-menu {
  .v-list-item__prepend > .v-badge ~ .v-list-item__spacer,
  .v-list-item__prepend > .v-icon ~ .v-list-item__spacer,
  .v-list-item__prepend > .v-tooltip ~ .v-list-item__spacer {
    width: 24px;
  }

  .v-list-group__items {
    --indent-padding: 1.25rem;
  }

  .v-list-subheader + .v-list {
    padding-top: 0;
    padding-bottom: 1rem;
  }

  .v-list-item--density-default.v-list-item--one-line {
    min-height: 40px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .v-list-item__overlay,
  .v-ripple__container {
    border-radius: 0 999px 999px 0;
  }

  .v-list-item__content {
    hyphens: auto;
    overflow-wrap: normal;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: normal;
    word-wrap: break-word;
  }
}

.v-navigation-drawer {
  @apply border-r border-app-separator;
}
</style>
