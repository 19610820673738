import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesInsurance: RouteMetaConfig[] = [
  {
    path: "/insurances",
    name: routeNames.insuranceOverview,
    component: () => import("./views/InsuranceListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "recurring_products.view",
      listFilterName: "insurances",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.insuranceCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "recurring_products.create",
          },
          component: () => import("./views/InsuranceCreateView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/insurances/:id(\\d+)",
    name: routeNames.insuranceDetails,
    component: () => import("./views/InsuranceDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "recurring_products.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.insuranceEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/InsuranceEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/storefront",
          name: routeNames.insuranceEditStorefront,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/InsuranceEditStorefrontForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/tax",
          name: routeNames.insuranceEditTax,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/InsuranceEditTaxForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "booking-plan",
          name: routeNames.insuranceAddBookingPlan,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/InsuranceCreateBookingPlanForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "booking-plan/:bookingPlanId(\\d+)",
          name: routeNames.insuranceEditBookingPlan,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/InsuranceEditBookingPlanForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];

export const insuranceRouteFactory = {
  insuranceDetail(insuranceId: number | string) {
    return {
      name: routeNames.insuranceDetails,
      params: {
        id: insuranceId,
      },
    };
  },
};
