<template>
  <v-btn
    :disabled="disabled"
    :color="color"
    :loading="loading"
    :class="classes"
    :type="type"
    :size="mappedSize"
    :form="form"
    :to="to"
    :append-icon="iconPosition === 'end' ? icon : undefined"
    :prepend-icon="iconPosition === 'start' ? icon : undefined"
    :variant="outlined ? 'outlined' : 'flat'"
    v-bind="$attrs"
  >
    <template v-if="$slots.loader" #loader><slot name="loader" /></template>
    <slot />
  </v-btn>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { IButtonProps } from "@/modules/ui/buttons/ui-button-types";

type Props = IButtonProps;

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  iconPosition: "start",
  loading: false,
  disabled: false,
  variant: "primary",
  type: "button",
  size: "md",
  outlined: false,
  narrow: false,
  to: undefined,
  form: undefined,
});

const mappedSize = computed(() => {
  if (props.size === "sm") {
    return "small";
  }

  if (props.size === "lg") {
    return "large";
  }

  return "default";
});

const classes = computed(() => {
  const classes: string[] = [`ui-button-variant-${props.variant}`];

  if (props.variant === "secondary") {
    classes.push("text-primary");
  }

  if (props.variant === "danger") {
    classes.push("text-error");
    classes.push("bg-white");

    if (props.outlined) {
      classes.push("border border-current");
    }
  }

  if (props.variant === "danger-full") {
    classes.push("bg-error text-white");
  }

  if (props.variant === "warning-full") {
    classes.push("bg-warning text-white");
  }

  if (props.variant === "clear") {
    classes.push("ui-button-clear");
    if (!props.disabled) {
      classes.push("text-primary");
    } else {
      classes.push("clear-bg");
    }
  }

  if (props.variant === "black-clear") {
    classes.push("ui-button-clear");
    classes.push("black--text");
  }

  if (props.narrow) {
    classes.push("ui-button-narrow");
  }

  if (props.outlined) {
    classes.push("ui-button-outlined");
  }

  classes.push(`ui-button-${props.size}`);

  return classes;
});

const color = computed(() => {
  return ["clear"].includes(props.variant) ? "" : props.variant;
});
</script>

<style scoped lang="scss">
.ui-button-clear {
  &,
  &.theme--light.v-btn.v-btn--has-bg {
    background-color: white;
  }
}

:deep(.v-icon) {
  color: currentColor !important;
}

.btn-icon-spacer {
  margin-right: 0.5em;
  color: currentColor !important;
}

.ui-button-narrow {
  &.v-btn:not(.v-btn--round).v-btn--size-default {
    --v-btn-height: 44px;
    padding: 6px;
    min-width: 44px;
    min-height: 44px;

    :deep(.v-icon) {
      transform: scale(1.36);
    }
  }
}

.ui-button-outlined {
  &.v-btn:not(.v-btn--round).v-size--default {
    background: white;
  }
}

.ui-button-lg {
  --v-btn-height: 40px;
}

:deep(.v-btn__content) {
  position: relative;
  max-height: 100%;
}

:deep(.v-btn__content > *) {
  max-height: 100%;
}

.clear-bg :deep(.v-btn__overlay) {
  background: none;
}
</style>
