import type { RouteLocationNamedRaw } from "vue-router";

import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesProduct: RouteMetaConfig[] = [
  {
    path: "/products",
    name: routeNames.productOverview,
    component: () => import("./views/ProductListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "products.view",
      listFilterName: "products",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.productCreate,
          path: "one-time/create",
          meta: {
            requiresAuth: true,
            permission: "products.create",
          },
          component: () => import("./views/OneTimeProductCreateView.vue"),
        },
        { fullscreen: true }
      ),
      buildModalRoute(
        {
          name: routeNames.recurringProductCreate,
          path: "recurring/create",
          meta: {
            requiresAuth: true,
            permission: "products.create",
          },
          component: () => import("./views/RecurringProductCreateView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/products/one-time/:id(\\d+)",
    name: routeNames.productDetails,
    component: () => import("./views/OneTimeProductDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "products.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.productEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "products.update",
          },
          component: () => import("./views/ProductEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/booking-portal",
          name: routeNames.productEditBookingPortal,
          meta: {
            requiresAuth: true,
            permission: "products.update",
          },
          component: () => import("./views/ProductEditBookingPortalForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/tax",
          name: routeNames.productEditTax,
          meta: {
            requiresAuth: true,
            permission: "products.update",
          },
          component: () => import("./views/ProductEditTaxForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
  {
    path: "/products/recurring/:id(\\d+)",
    name: routeNames.recurringProductDetails,
    component: () => import("./views/RecurringProductDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "recurring_products.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.recurringProductEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/ProductEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/booking-portal",
          name: routeNames.recurringProductEditBookingPortal,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/ProductEditBookingPortalForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/tax",
          name: routeNames.recurringProductEditTax,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/ProductEditTaxForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "booking-plan",
          name: routeNames.recurringProductAddBookingPlan,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          component: () => import("./views/ProductCreateBookingPlanForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "booking-plan/:bookingPlanId(\\d+)",
          name: routeNames.recurringProductEditBookingPlan,
          meta: {
            requiresAuth: true,
            permission: "recurring_products.update",
          },
          props: {
            type: "recurring-product",
          },
          component: () =>
            import(
              "@/modules/insurances/views/InsuranceEditBookingPlanForm.vue"
            ),
        },
        { fullscreen: false }
      ),
    ],
  },
];

export const productRoutesFactory = {
  productDetail(id: number | string): RouteLocationNamedRaw {
    return {
      name: routeNames.productDetails,
      params: {
        id,
      },
    };
  },
};
