<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_27160_33751"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="25"
    >
      <rect x="0.988281" y="0.774414" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_27160_33751)">
      <path
        d="M12.9883 21.7744C10.9716 21.7744 9.16328 21.1786 7.56328 19.9869C5.96328 18.7952 4.88828 17.2411 4.33828 15.3244C4.25495 15.0244 4.28411 14.7411 4.42578 14.4744C4.56745 14.2077 4.78828 14.0411 5.08828 13.9744C5.37161 13.9077 5.62578 13.9702 5.85078 14.1619C6.07578 14.3536 6.22995 14.5911 6.31328 14.8744C6.74661 16.3411 7.58411 17.5244 8.82578 18.4244C10.0674 19.3244 11.4549 19.7744 12.9883 19.7744C14.9383 19.7744 16.5924 19.0952 17.9508 17.7369C19.3091 16.3786 19.9883 14.7244 19.9883 12.7744C19.9883 10.8244 19.3091 9.17025 17.9508 7.81191C16.5924 6.45358 14.9383 5.77441 12.9883 5.77441C11.8383 5.77441 10.7633 6.04108 9.76328 6.57441C8.76328 7.10775 7.92161 7.84108 7.23828 8.77441H8.98828C9.27161 8.77441 9.50911 8.87025 9.70078 9.06191C9.89245 9.25358 9.98828 9.49108 9.98828 9.77441C9.98828 10.0577 9.89245 10.2952 9.70078 10.4869C9.50911 10.6786 9.27161 10.7744 8.98828 10.7744H4.98828C4.70495 10.7744 4.46745 10.6786 4.27578 10.4869C4.08411 10.2952 3.98828 10.0577 3.98828 9.77441V5.77441C3.98828 5.49108 4.08411 5.25358 4.27578 5.06191C4.46745 4.87025 4.70495 4.77441 4.98828 4.77441C5.27161 4.77441 5.50911 4.87025 5.70078 5.06191C5.89245 5.25358 5.98828 5.49108 5.98828 5.77441V7.12441C6.83828 6.05775 7.87578 5.23275 9.10078 4.64941C10.3258 4.06608 11.6216 3.77441 12.9883 3.77441C14.2383 3.77441 15.4091 4.01191 16.5008 4.48691C17.5924 4.96191 18.5424 5.60358 19.3508 6.41191C20.1591 7.22025 20.8008 8.17025 21.2758 9.26191C21.7508 10.3536 21.9883 11.5244 21.9883 12.7744C21.9883 14.0244 21.7508 15.1952 21.2758 16.2869C20.8008 17.3786 20.1591 18.3286 19.3508 19.1369C18.5424 19.9452 17.5924 20.5869 16.5008 21.0619C15.4091 21.5369 14.2383 21.7744 12.9883 21.7744Z"
        fill="currentColor"
      />
      <path
        d="M11.9883 16.7744V13.7744H8.98828C8.70495 13.7744 8.46745 13.6786 8.27578 13.4869C8.08411 13.2952 7.98828 13.0577 7.98828 12.7744C7.98828 12.4911 8.08411 12.2536 8.27578 12.0619C8.46745 11.8702 8.70495 11.7744 8.98828 11.7744H11.9883V8.77441C11.9883 8.49108 12.0841 8.25358 12.2758 8.06191C12.4674 7.87025 12.7049 7.77441 12.9883 7.77441C13.2716 7.77441 13.5091 7.87025 13.7008 8.06191C13.8924 8.25358 13.9883 8.49108 13.9883 8.77441V11.7744H16.9883C17.2716 11.7744 17.5091 11.8702 17.7008 12.0619C17.8924 12.2536 17.9883 12.4911 17.9883 12.7744C17.9883 13.0577 17.8924 13.2952 17.7008 13.4869C17.5091 13.6786 17.2716 13.7744 16.9883 13.7744H13.9883V16.7744C13.9883 17.0577 13.8924 17.2952 13.7008 17.4869C13.5091 17.6786 13.2716 17.7744 12.9883 17.7744C12.7049 17.7744 12.4674 17.6786 12.2758 17.4869C12.0841 17.2952 11.9883 17.0577 11.9883 16.7744Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
