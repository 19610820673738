<template>
  <v-dialog
    v-model="isOpen"
    persistent
    :width="width"
    max-width="100%"
    transition="dialog-bottom-transition"
    v-bind="$attrs"
  >
    <AppCardModalBody :data-test-id="dataTestId" :title="title">
      <div v-if="icon">
        <div class="confirm-icon mb-4 text-center">
          <v-icon :color="color">{{ icon }}</v-icon>
        </div>
      </div>

      <div
        class="text-sm font-medium text-app-black"
        :class="{ 'text-center': icon }"
      >
        <slot
          ><span class="whitespace-pre-wrap">{{ t(message || "") }}</span></slot
        >
      </div>

      <template #footer>
        <AppModalFormButtons
          :loading="loading"
          :color="color"
          data-test-id-cancel="cancel-button"
          :cancel-text="cancelLabel ? $t(cancelLabel) : undefined"
          :submit-text="okLabel ? $t(okLabel || '') : undefined"
          @click:cancel="close(false)"
          @click:submit="close(true)"
        />
      </template>
    </AppCardModalBody>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import AppCardModalBody from "@/components/modals/AppCardModalBody.vue";
import AppModalFormButtons from "@/components/modals/AppModalFormButtons.vue";
import { useTranslate } from "@/composables/use-translate";
import type { ITableRowConfirmData } from "@/types/action-menu-types";

const { t } = useTranslate();

interface Props {
  icon?: string;
  okLabel?: ITableRowConfirmData["okLabel"];
  cancelLabel?: ITableRowConfirmData["cancelLabel"];
  title?: ITableRowConfirmData["title"];
  message?: ITableRowConfirmData["message"];
  color?: ITableRowConfirmData["color"];
  loading?: boolean;
  width?: string | number;
  resolve?: (value: boolean) => void;
  dataTestId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  cancelLabel: "confirmDialog.cancel",
  okLabel: "confirmDialog.ok",
  color: "primary",
  loading: false,
  message: undefined,
  title: undefined,
  width: "500px",
  resolve: undefined,
  dataTestId: "confirm-dialog",
});

const emit = defineEmits<{
  (e: "closed", data: boolean): void;
}>();

const isOpen = ref(true);

function close(value: boolean): void {
  emit("closed", value);
  props.resolve?.(value);
}
</script>

<style scoped lang="scss">
.confirm-body {
  line-height: 1.8;
}

.confirm-icon :deep(.v-icon) {
  font-size: 50px;
}
</style>
