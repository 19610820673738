import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";

export const chatLogsRoutes: RouteMetaConfig[] = [
  {
    path: "/chat-logs",
    name: routeNames.chatLogsOverview,
    component: () =>
      import("@/modules/chat-logs/views/ChatLogsOverviewView.vue"),
    meta: {
      requiresAuth: true,
      permission: "jane_conversations.view",
    },
  },
];
